/* eslint-disable camelcase */
/* eslint-disable camelcase */
/* eslint-disable camelcase */
import dynamic from 'next/dynamic';

const vietnamTickets = dynamic(() => import(`./layoutData/vietnamTickets`), {
  ssr: true,
  loading: () => null,
});


const DATA_TEMPLATES = {
  vietnamTickets
};
export default DATA_TEMPLATES;
